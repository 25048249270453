$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	// countUpInit();
	productInit();
	dealerInit();

	accordionCollapse();
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	toggleBackdrop = $(".toggle-backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			toggleBackdrop.removeClass('open');
			body.removeClass('overflow-hidden');
			buttonMenu.removeClass('open');
			mobileWrap.removeClass('open');

			// Other
			$('.box-category').removeClass('active');
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		let mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-right",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		let formNewsletter = $(".form-newsletter-append").mapping({
			mobileWrapper: "body",
			mobileMethod: "appendTo",
			desktopWrapper: ".home-newsletter .caption .top",
			desktopMethod: "appendTo",
			breakpoint: 0,
		});
	},
	toggleMenuMB: () => {
		buttonMenu.on("click", function () {
			if (mobileWrap.hasClass('open')){
				$(this).removeClass('open');
				mobileWrap.removeClass("open");
				backdrop.removeClass('open');
			} else{
				$(this).addClass('open');
				mobileWrap.addClass("open");
				backdrop.addClass('open');
			}
		});
		$('.close-menu').on('click', function () {
			buttonMenu.removeClass('open');
			mobileWrap.removeClass("open");
			backdrop.removeClass('open');
		})
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleMenuMB();
	}
}

var defaultSettingSwiper = {
	preventInteractionOnTransition: true,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
}

const homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 12,
	navigation: {
		nextEl: ".home-banner-slider .button-next",
		prevEl: ".home-banner-slider .button-prev",
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
});

const homeProductSlider = new Swiper(".home-product-slider .swiper", {
	...defaultSettingSwiper,
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	spaceBetween: 12,
	slidesPerView: 1,
	navigation: {
		prevEl: ".home-product-slider .button-prev",
		nextEl: ".home-product-slider .button-next",
	},
	pagination: {
		el: ".home-product-slider .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		420: {
			slidesPerView: 2,
		},
		576: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 3,
		},
		1280: {
			slidesPerView: 3,
		},
	},
});

const homeBrandSlider = new Swiper(".home-brand-slider .swiper", {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 1,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 3000,
	spaceBetween: 16,
	parallax: true,
	slidesPerView: 'auto',
	freeMode: true,
	navigation: {
		prevEl: ".home-brand-slider .button-prev",
		nextEl: ".home-brand-slider .button-next",
	},
	pagination: {
		el: ".home-brand-slider .swiper-pagination",
		clickable: true,
	},
});

const homeNewsSlider = new Swiper(".home-news-slider .swiper", {
	...defaultSettingSwiper,
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	spaceBetween: 12,
	slidesPerView: 1,
	navigation: {
		prevEl: ".home-news-slider .button-prev",
		nextEl: ".home-news-slider .button-next",
	},
	pagination: {
		el: ".home-news-slider .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		420: {
			slidesPerView: 2,
		},
		576: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 3,
		},
		1280: {
			slidesPerView: 3,
		},
	},
});

const aboutBrandSwiper = new Swiper(".about-brand-swiper .swiper", {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	slidesPerView: 'auto',
	speed: 1000,
	spaceBetween: 20,
	navigation: {
		nextEl: ".about-brand-swiper .button-next",
		prevEl: ".about-brand-swiper .button-prev",
	},
	pagination: {
		el: ".about-brand-swiper .swiper-pagination",
		clickable: true,
	},
});

const productDetailThumbs = new Swiper(".product-detail-thumbs .swiper", {
	spaceBetween: 16,
	slidesPerView: 4,
	freeMode: true,
	watchSlidesProgress: true,
});
const productDetailTop = new Swiper(".product-detail-top .swiper", {
	...defaultSettingSwiper,
	spaceBetween: 10,
	navigation: {
		nextEl: ".product-detail-wrap .button-next",
		prevEl: ".product-detail-wrap .button-prev",
	},
	thumbs: {
		swiper: productDetailThumbs,
	},
});

$('.three-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-three-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-three-${index}`);
	$(this).find('.button-prev').addClass(`prev-three-${index}`);
	$(this).find('.button-next').addClass(`next-three-${index}`);

	const threeSlider = new Swiper(`.swiper-three-${index}`, {
		...defaultSettingSwiper,
		// loop: true,
		// autoplay: {
		// 	delay: 5000,
		// 	disableOnInteraction: false,
		// 	pauseOnMouseEnter: true,
		// },
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.three-slider .prev-three-${index}`,
			nextEl: `.three-slider .next-three-${index}`,
		},
		pagination: {
			el: `.three-slider .pagination-three-${index}`,
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});
});

$('.four-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-four-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-four-${index}`);
	$(this).find('.button-prev').addClass(`prev-four-${index}`);
	$(this).find('.button-next').addClass(`next-four-${index}`);

	const fourSlider = new Swiper(`.swiper-four-${index}`, {
		...defaultSettingSwiper,
		// loop: true,
		// autoplay: {
		// 	delay: 5000,
		// 	disableOnInteraction: false,
		// 	pauseOnMouseEnter: true,
		// },
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 2,
		observer: true,
		observeParents: true,
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			prevEl: `.four-slider .prev-four-${index}`,
			nextEl: `.four-slider .next-four-${index}`,
		},
		pagination: {
			el: `.four-slider .pagination-four-${index}`,
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 16,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
		},
	});
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	Fancybox.bind('[data-fancybox="product-contact"]', {
		parentEl: document.forms[0], // Element containing main structure
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function popupInit() {
	$('[data-popup]').each(function () {
		$(this).on('click', function (e) {
			e.preventDefault();
			let dataPopup = $(this).data('src');
			$(`#${dataPopup}`).toggleClass('active');
			$('.popup').not(`#${dataPopup}`).removeClass('active');
		});
	});
	$('.popup').each(function () {
		$(this).find('.btn-close-popup').on('click', function (e) {
			e.preventDefault();
			$(this).parents('.popup').removeClass('active');
		});
	});
	// Additional code to close the popup by clicking anywhere outside it
	$(document).on('click', function (e) {
		if (!$(e.target).closest('.popup').length && !$(e.target).closest('[data-popup]').length) {
			$('.popup').removeClass('active');
		}
	});
}

function countUpInit() {
	$('.count-up').each(function (index) {
		$(this).attr('id', `countUp-${index}`)
		let endVal = $(this).data('count');

		let options = {
			separator: '.',
			enableScrollSpy: true,
			scrollSpyDelay: 100,
			scrollSpyOnce: true,
			// onStartCallback: () => console.log('countUp started', Date.now()),
			// onCompleteCallback: () => console.log('countUp completed', Date.now()),
		};

		let countUp = new CountUp(`countUp-${index}`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}

		countUp.handleScroll();
	});
}



function productInit(){
	// Toggle category
	$('.btn-toggle-category').on('click', function () {
		if($('.box-category').hasClass('active')){
			$('.box-category').removeClass('active');
			backdrop.removeClass('open');
			body.removeClass('overflow-hidden');
		} else{
			$('.box-category').addClass('active');
			backdrop.addClass('open');
			body.addClass('overflow-hidden');
		}
	});
	$('.box-category .box-close').on('click', function () {
		$('.box-category').removeClass('active');
		backdrop.removeClass('open');
		body.removeClass('overflow-hidden');
	});

	// Toggle category
	$('.category-list .toggle-icon').on('click', function () {
		$(this).next().slideToggle().parents('.has-child').toggleClass('active').siblings().removeClass('active').find('.sub-category-list').slideUp();
	});

	// copy product title to form
	$('.product-detail-wrap .product-title').each(function () {
		let productTitle = $(this).text();
		$('.wrap-form .product-contact-title').text(productTitle);
		$('.wrap-form .product-contact-input input').val(productTitle);
	});


	// Toggle product content
	var productContent = $('.product-information .full-content').outerHeight();
	var maxHeight = 300;
	if (productContent > maxHeight){
		$('.product-information .full-content').css('max-height', maxHeight);
		$('.product-information .toggle-content').removeClass('hidden');
	}
	$('.product-information .toggle-content').on('click', function () {
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$('.product-information .full-content').css('max-height', maxHeight);
			$('html, body').animate({ scrollTop: $('.product-information').offset().top - 100 }, 200);
		} else{
			$(this).addClass('active');
			$('.product-information .full-content').css('max-height', productContent);
		}
	});
}

function dealerInit(){
	$('#list li').on('click', function () {
		let dataMap = $(this).data('map');
		$(this).addClass('active').siblings().removeClass('active');
		$('.iframe-maps iframe').attr('src', dataMap);
	});
}

function accordionCollapse() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.find('.accordion-content').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-content').slideUp()
	});
}

if($('.info-list').length) {
	new Waypoint({
		element: document.querySelector('.info-list'),
		handler: function () {
			countUpInit()
			this.destroy() //for once
		},
		offset: '50%'
	});
}
